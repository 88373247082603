<template>
    <div>
        <vx-card>
            <vue-bootstrap4-table
                :rows="users"
                :columns="columns"
                :config="config"
                :actions="actions"
                :inline-edit-callback="true"
                @on-change-query="onChangeQuery"
                :total-rows="total_rows"
                columnSelection
                additionalRows
            >

                <template slot="active" slot-scope="props" class="column-active">
                    <div class="vbt-table-td">
                        <div class="pl-2">
                            <vs-row>
                                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                                    <vx-tooltip text="Inaktiv">
                                        <span class="mega--circle red" v-if="props.row.active == '0'"></span>
                                    </vx-tooltip>
                                    <vx-tooltip text="Aktiver Mitarbeiter">
                                        <span class="mega--circle green" v-if="props.row.active == '1'"></span>
                                    </vx-tooltip>
                                </vs-col>
                            </vs-row>
                        </div>
                    </div>
                </template>

                <template slot="is_online" slot-scope="props" class="column-active">
                    <div class="vbt-table-td">
                        <div class="pl-2">
                            <vs-row>
                                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                                    <vx-tooltip text="Online">
                                        <span class="mega--circle bg-gray-300" v-if="props.row.is_online == '0'"></span>
                                    </vx-tooltip>
                                    <vx-tooltip text="Nicht Online">
                                        <span class="mega--circle green" v-if="props.row.is_online == '1'"></span>
                                    </vx-tooltip>
                                </vs-col>
                            </vs-row>


                        </div>
                    </div>
                </template>

                <template slot="row_actions" slot-scope="props">
                    <div class="column-actions-group">
                    <vs-button color="danger" v-if="props.row.active" type="filled" icon="close" size="small" title="Deaktivieren"
                               @click="toggleUserActive(props.row.id, props.row.active)"></vs-button>
                    <vs-button color="success" v-if="!props.row.active" type="filled" icon="check" size="small" title="Aktivieren"
                               @click="toggleUserActive(props.row.id, props.row.active)"></vs-button>
                    <vs-button color="warning" type="filled" icon="edit" size="small"
                               :to="'/employee/'+props.row.id"></vs-button>
                    </div>
                </template>
              <template slot="additional_row" slot-scope="props">
                  Mehr Informationen zu: {{props.row.detail.first_name}} {{props.row.detail.last_name}}
              </template>
                <template slot="empty-results">
                    Keine Mitarbeiter gefunden.
                </template>
            </vue-bootstrap4-table>
        </vx-card>
    </div>
</template>

<script>
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'
    import qs from "qs";
    import ApiService from "../../../api";
    import QueryHelper from "@/mixins/helper/query.helper";

    export default {
        components: {
            VueBootstrap4Table
        },
        data() {
            return {
                users: [],
                roleOptions: [],
                actions: [
                    {
                        btn_text: "Hinzufügen",
                        router_link: '/employees/add',
                        color: 'success'
                    },
                ],
                columns: [
                    {
                        label: "id",
                        name: "id",
                        showCol: false,
                        hide: true,
                        uniqueId: true
                    }, {
                        label: "Nachname",
                        name: "detail.last_name",
                        inlineEdit: true,
                        filter: {
                            type: "simple",
                            placeholder: "Nachname eingeben..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    }, {
                        label: "Vorname",
                        name: "detail.first_name",
                        filter: {
                            type: "simple",
                            placeholder: "Vorname eingeben..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                    {
                        label: "E-Mail",
                        name: "email",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        filter: {
                            type: "simple",
                            placeholder: "E-Mail eingeben..."
                        },
                        showCol: true,
                    },
                    {
                        label: "Rolle",
                        name: "roles.0.long_name",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        inlineEdit: true,
                        filter: {
                            type: "select",
                            mode: "single",
                            closeDropdownOnSelection: true,
                            placeholder: "Rolle auswählen",
                            options: this.roleOptions,
                            width: 170+'px'
                        },
                        showCol: true,
                    },
                    {
                        label: "Aktiv",
                        name: "active",
                        slot_name: "active",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                    {
                        label: "Online",
                        name: "is_online",
                        slot_name: "is_online",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                    {
                        label: "Letzer Login",
                        name: "last_login",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                    },
                    {
                        label: "Typ",
                        name: "typ",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                    },
                    {
                        label: "Erstellt",
                        name: "created_at",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                    },
                    {
                        label: "Letzer Update",
                        name: "updated_at",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                    },
                    {
                        label: "Actions",
                        slot_name: "row_actions",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'actions-column',
                        showCol: true,
                    }],
                config: {
                    checkbox_rows: false,
                    rows_selectable: false,
                    page: 1,
                    per_page: 10,
                    show_refresh_button: false,
                    show_reset_button: false,
                    highlight_row_hover_color: '#f8f8f8',
                    inlineEditCallback: this.userInlineEdit,
                    server_mode: true,
                    csvFileName: 'employees_export' // CSV Filename
                },
              queryParams: {
                sort: [],
                filters: [],
                global_search: "",
                per_page: 10,
                page: 1,
              },
              total_rows: 0,
            }
        },
        created() {
            this.fetchRoles();
        },
        computed: {
          requestParams() {
            let queryParams = QueryHelper.parseRequestParams(this.queryParams);




            queryParams.filter.push({
              expression: 'group',
              filter: [
                {
                  field: 'active',
                  value: 1,
                  expression: 'contains',
                  operator: 'or'

                },
                {
                  field: 'active',
                  value: 0,
                  expression: 'contains',
                  operator: 'or'

                }
              ]
            });


            queryParams.order =
                {
                  'field': 'created_at',
                  'direction': 'asc'
                }
            ;

            return queryParams;
          },
        },
        methods: {
            toggleUserActive(id, current) {

                const payload = {
                    active: !parseInt(current)
                }

                ApiService.put('users/' + id, payload).then(response => {
                    this.fetchUsers();

                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Der Status wurde erfolgreich geändert',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    })

                }).catch(response => {

                })
            },
            userInlineEdit(payload) {
                let put_payload = {};

                put_payload[payload.column.name] = payload.value;

                /*
                TODO: request for inline edit
                ApiService.put('users/' + payload.id, put_payload).then(response => {
                    this.fetchUsers();

                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Der Status wurde erfolgreich geändert',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    })

                }).catch(response => {

                })
                */
            },
            fetchUsers() {
                this.$vs.loading()

                ApiService.get('users', {
                  params: this.requestParams,
                  paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    if (response.data.status === 'success') {
                        this.users = response.data.result;
                        this.total_rows = response.data.total;
                        this.$vs.loading.close()
                    } else {
                        this.$vs.notify({
                            title: 'Fehler',
                            text: 'Es ist ein Fehler aufgetreten',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'error'
                        })
                    }
                }).catch(response => {

                    this.$vs.notify({
                        title: 'Fehler',
                        text: 'Es ist ein Fehler aufgetreten',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'error'
                    })
                    this.$vs.loading.close()
                })
            },
            fetchRoles() {
                let options = []

                ApiService.get('roles').then(response => {
                    let roles = response.data.result;
                    let options = [];

                    roles.forEach((role) => {
                        options.push({id: role.id, label: role.long_name})
                    });

                    this.columns[4].filter.options = options;

                }).catch(response => {

                })
            },
            onChangeQuery(queryParams) {
              this.queryParams = queryParams;
              this.fetchUsers();
            },
        },
        mounted() {
        }
    }

</script>
